import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideNzI18n, zh_CN} from 'ng-zorro-antd/i18n';
import zh from '@angular/common/locales/zh';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {provideAnimations} from '@angular/platform-browser/animations';
import {ErrorHandlerInterceptor} from "./service/error-handler-interceptor";
import {registerLocaleData} from "@angular/common";

registerLocaleData(zh);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideNzI18n(zh_CN),
    importProvidersFrom(HttpClientModule),
    provideAnimations(),
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true}

  ]
};
