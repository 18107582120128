import {Routes} from '@angular/router';

export const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.routes').then(c => c.AdminRoutes),
  },
  {
    path: 'desktop',
    loadChildren: () => import('./pages/desktop/desktop.routes').then(c => c.DesktopRoutes)
  },
  {
    path: 'ding',
    loadChildren: () => import('./pages/ding/ding.routes').then(c => c.DingRoutes)
  },
  {
    path: '403',
    loadComponent: () => import('./pages/forbidden/forbidden.component').then(c => c.ForbiddenComponent)
  },
  {
    path: 'error',
    loadComponent: () => import('./pages/forbidden/forbidden.component').then(c => c.ForbiddenComponent)
  },
  {
    path: "**",
    redirectTo: "403"
  }
];
