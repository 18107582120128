import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {catchError, Observable, throwError} from 'rxjs';
import {Router} from "@angular/router";
import {NzMessageService} from "ng-zorro-antd/message";
import {environment} from "../../environments/environment";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private message: NzMessageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newRequest = req.clone({
      url: environment.apiUrl + req.url,
      withCredentials: true
    })
    return next.handle(newRequest)
      .pipe(catchError(error => {
        if (error.status == 403 || error.status == 403) {
          if (error.error.path != '/api/user') {
            this.router.navigate(['/', '403'])
          }
        } else if (!error.ok) {
          if (error.error) {
            this.message.error(error.error.message)
          } else {
            this.message.error(error.message)
          }
        }
        return throwError(error);
      }));
  }
}
