import {Component, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router, RouterOutlet} from '@angular/router';
import {NgIf} from "@angular/common";
import {NzIconService} from "ng-zorro-antd/icon";
import {environment} from "../environments/environment";
import {NzSpinComponent} from "ng-zorro-antd/spin";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgIf, NzSpinComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {


  private inDing: boolean;
  navLoading = false;

  constructor(private router: Router,
              icons: NzIconService) {
    icons.changeAssetsSource(environment.iconPrefix)
    // @ts-ignore
    this.inDing = !!window.dingtalk
    this.router.events
      .subscribe(evt => {
        if (evt instanceof NavigationStart) {
          this.navLoading = true
        } else if (evt instanceof NavigationEnd) {
          this.navLoading = false
        }
      })
  }


  ngOnInit(): void {
    // if (this.inDing) {
    //   this.router.navigate(['/', 'desktop'], {replaceUrl: true})
    // } else {
    //   this.router.navigate(['/', 'admin'])
    // }

  }

}
